.activities {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  font-family: firaGo, sans-serif;
}

.activities h1 {
  margin-top: 60px;
  
  margin-bottom: 0px;
  text-align: center;
  font-size: 2rem;
  color: #dfb759;
  font-family: firaGo, sans-serif;
}

.actpageCont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 8%;
  column-gap: 8%;
  padding: 54px;
  padding-bottom: 80px;
  padding-top: 10%;
}

@media (max-width: 768px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
    padding-top: 40px;
    padding-bottom: 120px;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    justify-items: center;
  }
}
