.card {
  position: relative;
  overflow: hidden;
  width: 90%;
  height: 82%;
  justify-self: center;
  margin-bottom: 20px;
  align-self: center;
  cursor: url('../../Assets/picture_16607066.png') 0 0, auto;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%); 
  transition: filter 0.7s ease-in-out, transform 0.7s ease-in-out;
  transform: translateY(0); 
}

.card:hover img {
  filter: none; 
  transform: translateY(-10px); 
}

.card-wrapper {
  position: relative;
  transition: all 0.7s ease;
  border-bottom: 3px solid #dfb759;
  border-top: 0.5px solid #dfb759;
  /* width: 100%; */
  aspect-ratio: 1 / 1;
  overflow: hidden;
  margin-bottom: 15px;
  padding-top: 5%;
  background-color: #dfb75919;
}

.card-wrapper::before,
.card-wrapper::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 0;
  background-color: #dfb759;
  transition: height 0.7s ease;
}

.card-wrapper::before {
  bottom: 0;
  left: 0;
}

.card-wrapper::after {
  bottom: 0;
  right: 0;
}

.card-wrapper:hover::before,
.card-wrapper:hover::after {
  height: 100%;
}

.card-category {
  margin-top: 10px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #dfb759;
}

.card-category p {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #dfb759;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
    height: 350px;
  }

  .card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0;
    margin: 0;
  }

  .card-wrapper {
    height: 400px; 
    width: 95%;
    padding: 5%;
    box-shadow: none;
    border: 1px solid #dfb759;
  }

  .card-category {
    margin-top: 5px;
    font-size: 1.2rem;
  }

  .card-category p {
    font-size: 1rem;
    letter-spacing: 1px;
  }
}