.footerContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  color: #dfb759;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  z-index: 9;
  font-family: firaGo light, sans-serif;
}

.footerContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  /* max-width: 1200px; */
}

.footerContent p {
  margin: 0;
  font-size: 1em;
  text-align: center;
  font-family: firaGo light, sans-serif;
}

.bestSoftLink {
  color: #dfb759;
  text-decoration: none; /* ხაზის მოცილება */
  font-weight: bold; /* ტექსტის გამოყოფისთვის */
  transition: color 0.3s ease; /* ჰოვერის ანიმაცია */
  font-family: firaGo light, sans-serif;
}

.bestSoftLink:hover {
  color: #b59346; /* ფერი შეიცვლება ჰოვერზე */
}
.flexMobile {
  display: flex;
  justify-content: space-between;
  width: 80%;
  gap: 5%;
  flex-direction: row;
}

@media (max-width: 768px) {
  .flexMobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
