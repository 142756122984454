.card-details {
  padding: 20px;
  background-color: black;
  color: #dfb759;
  margin: 0 auto;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: firaGo light, sans-serif;
}
.card-details h1 {
  align-self: start;
  margin-left: 10%;
  margin-bottom: 50px;
}

.photo-gallery {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1200px;
  width: 100%;
  padding-bottom: 40px;
}

.photo-gallery img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 30%;
}

.prev-button,
.next-button {
  color: #dfb759;
  gap: 10px;
  cursor: pointer;
  font-size: 16px;
  background-color: black;
  border: none;
}

.arrow-icon {
  width: 20px;
  height: 20px;
}

.prev-button:hover,
.next-button:hover {
  color: #b59346;
}
.subcategories {
  display: flex;
  gap: 10px;
  align-items: start;
  justify-content: start;
  margin-bottom: 70px;
  align-self: start;
  padding-left: 10%;
  flex-wrap: wrap;
}

.back-button {
  background-color: transparent;
  color: #dfb759;
  align-self: flex-start;
  margin-bottom: 10px;
  margin-left: 6%;
}
.back-button:hover {
  background-color: transparent;
  color: #b59346;
}
@media (max-width: 768px) {
  .photo-gallery {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    width: 100%;
    padding-bottom: 40px;
  }
  .card-details h1,
  .subcategories {
    padding-left: 0%;
    flex-wrap: wrap;
  }
  .back-button {
    margin-left: 0px;
  }
}
