@import "@fontsource/firago";


body {
  margin: 0;
  background-color: black;
  color: white;
  font-family: "FiraGO", sans-serif;
  cursor: url('./Assets/camera_3004613.png') 0 0, auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, FiraGo;
}
