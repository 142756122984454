@font-face {
  font-family: "Geopixel";
  src: url("../../Assets/Fonts/geopixel.ttf") format("truetype"); /* შეაცვლე გზას ფონტის რეალურ ადგილმდებარეობაზე */
  font-weight: normal;
  font-style: normal;
}
.carousel {
  position: relative;
  width: 80%;
  max-height: 80vh;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #e1d4b5;
  position: relative;
  margin-bottom: 100px;
}
.homeMainText {
  position: absolute;
  color: #e7c474;
  width: 50%;
  padding: 5%;
  height: 100%;
  align-self: center;
  font-family: "FiraGO light", sans-serif;
  line-height: 1.3;
}

.homeMainText h1 {
  font-family: "Geopixel", sans-serif;
  font-size: 2.5rem;
  font-weight: normal;
  color: black;
  -webkit-text-stroke: 0.9px #e7c474;
  position: relative;
  letter-spacing: 5px;
  cursor: zoom-in;
}

.homeMainText h1 span {
  display: inline-block;
  transition: transform 0.3s ease, color 0.3s ease;
}

.homeMainText h1 .hover-effect {
  display: inline-block;
  transition: transform 0.3s ease, color 0.3s ease;
}

.homeMainText h1 .hover-effect:hover {
  transform: scale(1.5);
  -webkit-text-stroke: 1.7px #e7c474;
  cursor: zoom-in;
}

.slideLinkCont {
  display: inline-block; /* სიგანე ტექსტის მიხედვით */
  border: 1px solid #dfb759;
  border-right: 16px solid #dfb759;
  border-right: none;
  padding: 10px 17px 10px 10px;
  margin-top: 15px;
  background-color: rgba(0, 0, 0, 0.483);
  backdrop-filter: blur(5px);
  cursor: pointer;
  transition: all 0.3s ease; /* ნელი ცვლილება */
  position: relative;
  clip-path: polygon(
    0 0,
    /* მარცხენა ზედა */ calc(100% - 15px) 0,
    /* მარჯვენა ზედა ჩაკეცილი */ 100% 50%,
    /* მარჯვენა შუა */ calc(100% - 15px) 100%,
    /* მარჯვენა ქვედა ჩაკეცილი */ 0 100% /* მარცხენა ქვედა */
  );
}

.slideLinkCont:hover {
  border: 1px solid #1d1d1c;
  border-right: 16px solid #1d1d1c;
  border-right: none;
  background-color: #dfb75965; /* გავლარული ფონი */
  /* transform: scale(1.05); ოდნავ ზრდის ეფექტი */
}

.slide-link {
  color: #dfb759;
  text-decoration: none;
  display: inline-block;
  transition: transform 0.3s ease, color 0.3s ease; /* ფერის და პოზიციის ცვლილება */
}

.slideLinkCont:hover .slide-link {
  color: #1d1d1c;
  transform: translateX(5px); /* ტექსტი ოდნავ მარჯვნივ */
}

.carousel-images {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
  object-fit: fill;
}

.carousel-images img {
  width: 100%;
  max-height: 100%;
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-images img.active {
  display: block;
  opacity: 1;
}

.carousel-dots {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.dot {
  width: 15px;
  height: 15px;
  background-color: rgba(223, 183, 89, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.144);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.dot:hover {
  background-color: rgba(223, 183, 89, 0.8);
  transform: scale(1.2);
}

.dot.active {
  background-color: #dfb759;
}
a {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .carousel {
    width: 90%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homeMainText {
    width: auto;
    font-size: medium;
    padding-top: 15%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  /* .homeMainText h1 {
    font-size: 1.5rem;
    line-height: 1.9;
    font-weight: bold;
    color: transparent; 
    -webkit-text-stroke: 0.1px #666;
    margin-bottom: 20%;
  } */
  .homeMainText h1 {
    font-size: 2.1rem;
    line-height: 1.1;
    letter-spacing: 0px;
  }
  .carousel-images {
    height: auto;
    object-fit: cover;
  }
}
