.NavCont {
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
}

.UlCont {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 40px;
  list-style: none;
  z-index: 2;
  font-family: firaGo, sans-serif;
}

.UlCont li a {
  text-decoration: none;
  color: #dfb759;
}

.UlCont li a:hover {
  color: #8e7439;
}

.hamburger-menu {
  display: none;
}

@media (max-width: 768px) {
  .UlCont {
    list-style: none;
    text-align: center;
    opacity: 0;
    transform: translateX(+100%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #121211;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease, transform 1.2s ease;
  }

  .UlCont.open {
    opacity: 1;
    transform: translateY(0);
    font-family: firaGo, sans-serif;
  }

  .UlCont li {
    margin: 10px 0;
  }

  .UlCont li a {
    text-decoration: none;
    color: #dfb759;
    font-size: 24px;
    transition: color 0.7s ease;
  }

  .UlCont li a:hover {
    color: #8e7439;
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    z-index: 3;
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40px;
    height: 30px;
  }

  .burger .line {
    width: 100%;
    height: 3px;
    background-color: #dfb759;
    transition: all 0.4s ease;
  }

  .burger.open .line:nth-child(1) {
    transform: translateY(19px) rotate(-45deg);
  }

  .burger.open .line:nth-child(2) {
    opacity: 0;
  }

  .burger.open .line:nth-child(3) {
    transform: translateY(-8px) rotate(45deg);
  }
}
