.about-container {
  background-color: #000;
  background-image: url(../../Assets/img5.jpg);
  background-size: contain;
  /*   background-repeat: no-repeat; */
  background-position: right;
  min-height: 100vh;
  /*   padding: 50px 20px; */
  text-align: center;
  margin-bottom: 80px;
  font-family: firaGo, sans-serif;
}
.about-content {
  background-color: rgba(0, 0, 0, 0.483);
  backdrop-filter: blur(5px);
  width: 100%;
  padding: 20px 0px;
}
.about-content h1 {
  margin-bottom: 40px;
}

.about-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #dfb759;
}

.about-text {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #dfb759;
  padding: 0px 10%;
}

.about-highlight {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  color: #dfb759;
}

@media screen and (max-width: 680px) {
  .about-container {
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-content {
    background-color: #00000096;
    padding: 5%;
  }
  .about-text {
    padding: 0px 2%;
  }
}
