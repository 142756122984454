.header {
  background-color: black;
  color: #dfb759;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: firaGo light, sans-serif;
}

/* .lang {
    width: 50px;
    height: 35px;
  }
  .toggles {
    display: flex;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 2;
  } */

.mainLogo img {
  width: 120px;
  height: auto;
  padding: 20px;
}
.mainLogo:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.2s ease;
}
@media screen and (max-width: 680px) {
  .mainLogo img {
    width: 105px;
    height: auto;
    padding-left: 20px;
  }
}
